@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');

@keyframes blink {
  0%,
  100% {
    background-color: transparent;
  }
  50% {
    background-color: green;
  }
}
:root {
  --background-color: hsl(0, 0%, 5%);
  --pad: max(20px, 4vmin);
  --pad_canvas: max(25px, 4.1vmin);
  --pad_form: max(100px, 4.4vmin);
  --pad_form_md: max(1000px, 5vmin);
  --projects: calc(var(--pad) * 2 +96px) calc(var(--pad) * 2);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  background: #000000;
}
